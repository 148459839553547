<template>
  <QDialog
    v-model="couponStore.showCouponModal"
    persistent
  >
    <div class="relative px-10 w-full md:w-sm lg:w-md dark:bg-dark-1 light:bg-light-1">
      <div class="py-8 flex flex-col items-center justify-center gap-7">
        <QBtn
          v-close-popup
          icon="close"
          flat
          round
          dense
          class="absolute top-2 right-2"
        />
        <div class="text-sm font-black break-all">
          {{ authStore.coupon.title }}
        </div>
        <div class="text-5xl font-black mt-4">
          <CurrencyField :value="authStore.coupon.amount" />
        </div>
        <div class="text-base">
          POINT
        </div>
        <QBtn
          v-close-popup
          dense
          label="쿠폰받기"
          class="w-55 h-10 "
          color="secondary"
          :loading="couponStore.exchanging"
          @click="exchange"
        />
      </div>
    </div>
  </QDialog>
</template>

<script setup>
import CurrencyField from '@/components/Tables/Fields/CurrencyField.vue'
import { useCouponStore } from '@/stores/coupon'
import { useAuthStore } from '@/stores/auth'

const couponStore = useCouponStore()
const authStore = useAuthStore()

async function exchange(){
  await couponStore.exchange(authStore.coupon.id)
  await authStore.fetch()
}
</script>
