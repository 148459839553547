import { useAuthStore } from '@/stores/auth.js'
import { useCartStore } from '@/stores/cart.js'
import { usePregameStore } from '@/stores/pregame.js'
import { Notify } from 'quasar'
import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'
import { usePointStore } from '@/stores/point'
import { useCouponStore } from '@/stores/coupon'
import { useMiniGameStore } from '@/stores/miniGame'
import { useCasinoStore } from '@/stores/casino'
import { useCancelTokenStore } from '@/stores/common/cancelToken'

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  },
})

const guestRoutes = [
  'registration',
  'login',
  '404',
  '503',
  'home',
  'prematches',
  'pregames',
  'lives',
  'mini-games',
  'virtual-games',
]

const freeRoutes = [
  'registration',
  'login',
  '404',
  '503',
  'error',
  'home',
]

const defaultFetchUserRoutes = [
  'home',
  'prematches',
  'pregames',
  'lives',
  'mini-games',
  'virtual-games.show',
  'casinos',
  'slots',
  'pragmatic'
]


router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  const cartStore = useCartStore()
  const pregameStore = usePregameStore()
  const pointStore = usePointStore()
  const couponStore = useCouponStore()
  const miniGameStore = useMiniGameStore()
  const casinoStore = useCasinoStore()

  const isLogin = await authStore.check()

  if (isLogin) {
    await authStore.fetch()
  } else {
    if (!freeRoutes.includes(to.name)) {
      if (from.name) {
        authStore.showLoginModal = true
      }
      if (!guestRoutes.includes(to.name)) {
        return from
      }
    }
  }

  if (authStore.user.new_messages_count > 0 && to.name !== 'messages') {
    authStore.showMessageModel = true
  }

  if (to.name === 'registration') {
    authStore.showLoginModal = false
  }

  if (to.name === 'messages') {
    authStore.showMessageModel = false
  }

  if (to.name !== from.name) {
    cartStore.$reset()
    pregameStore.$reset()
  }

  if (to.name === 'registration' && !to.params.code) {
    authStore.showInviteCodeModal = true

    return false
  }

  if (to.name === 'coupons') {
    if (authStore.user.coupons.length > 0) {
      couponStore.showCouponModal = true
    } else {
      Notify.create({
        message: '보유중인 쿠폰이 없습니다',
      })
    }

    return false
  }

  if (to.name === 'exchanges') {
    if (authStore.user.point_balance > 0) {
      pointStore.showPointModal = true
    } else {
      Notify.create({
        message: '보유중인 포인트가 부족합니다.',
      })
    }

    return false
  }
  if (to.name === 'casinos.play' || to.name === 'slots.play') {
    casinoStore.$reset()
    if (['12', '53'].includes(to.params.id)) {
      Notify.create({
        message: '점검중',
      })
      return false
    }
  }

  // 动态跳转到 mini-game 的第一个游戏
  if (to.name === 'mini-games') {
    await miniGameStore.fetch()

    return { name: 'mini-games.show',
      params: {
        provider: miniGameStore.providers[0].id,
        game: miniGameStore.providers[0].games[0].id,
      },
    }
  }
})

export default router
